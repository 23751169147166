import { request3 } from './request'


export function promotions() {
    return request3({
        url: '/promotions'
    })
}
//跳转链接
export function click_links(tag, link_type) {
    return request3({
        url: '/click_links',
        params: { tag, link_type }
    })
}

//个人中心
export function dashboard3() {
    return request3({
        url: '/dashboard'
    })
}
//自营加入购物车
export function addCart3(stock_keeping_unit_id, quantity) {
    return request3({
        url: '/cart_items',
        data: {
            stock_keeping_unit_id,
            quantity
        },
        method: 'post',
    })
}